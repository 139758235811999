<template>
  <v-card tile :elevation="0" class="crud-box list-payments">
    <h1 class="mx-4">Lịch Sử Giao Dịch</h1>
    <v-layout wrap class="px-4 pt-4">
      <div style="overflow: visible">
        <!--        <v-btn color="error" class="mr-2" @click="onClickNew" v-if="UserRole === CONSTANTS.ROLES.CUSTOMER">-->
<!--        <v-btn color="error" class="mr-2" @click="onClickNew" v-if="UserRole === CONSTANTS.ROLES.CUSTOMER">
          <v-icon>mdi-currency-usd</v-icon>
          Nạp tiền
        </v-btn>-->
        <v-btn color="success" class="mr-2" @click="onClickWithdraw" v-if="UserRole === CONSTANTS.ROLES.CUSTOMER">
          <v-icon>mdi-currency-usd</v-icon>
          Rút tiền
        </v-btn>
        <v-btn color="primary" outlined class="mr-2" @click="onClickExport">
          <v-icon>mdi-database-export</v-icon>
          Export
        </v-btn>
        <v-btn color="success" class="mr-2" @click="onClickDiscount"  v-if="UserRole === CONSTANTS.ROLES.SUPER_ADMINISTRATOR">
          <v-icon>mdi-currency-usd</v-icon>
          Add Discount
        </v-btn>
      </div>
      <!---->
      <!-- <v-spacer></v-spacer> -->
      
    </v-layout>
    <v-layout wrap class="px-4 pt-4">
      <v-layout wrap style="max-width: calc(100% - 250px);">
        <v-text-field dense outlined @keyup.enter="doSearch" class="mr-2" label="Search Code"
                      v-model="searchFields.code.value"/>
        <v-text-field dense outlined @keyup.enter="doSearch" class="mr-2" label="Search User Email"
                      v-model="searchFields['user.email'].value"/>
        <v-autocomplete dense outlined @keyup.enter="doSearch" class="mr-2"
                        label="Search Service" :items="paymentListStatus" v-model="searchFields.status.value"/>
        <v-autocomplete dense outlined @keyup.enter="doSearch" class="mr-2"
                        label="Search Service" :items="paymentListType" v-model="searchFields.payment_type.value"/>
      </v-layout>
      <v-btn color="primary" @click="doSearch">
        <v-icon>mdi-magnify</v-icon>
        Search
      </v-btn>
    </v-layout>

    <!--card content-->
    <v-card tile :elevation="1" :disabled="tblLoading" outlined class="block-tbl mx-4">
      <v-data-table :headers="tblHeader" :items="listItems" mobile-breakpoint="0" :server-items-length="tblPagination.total" :loading="tblLoading"
                    :footer-props="tblConfig.footerProps" @dblclick:row="onClickRow" :options.sync="tblOptions"
                    :loading-text="tblConfig.text.loading" v-model="pickedItems" :show-select="tblConfig.showSelect">
        <template v-slot:no-data>
          {{ tblConfig.text.noItem }}
        </template>

        <template v-slot:item.no="{item}">
          {{ getIndexOfItem(item) }}
        </template>
        <template v-slot:item.order="{item}">
          {{ item.order ? item.order.name : '' }}
        </template>
        <template v-slot:item.order_code="{item}">
          {{ item.order ? item.order.code : '' }}
        </template>
        <template v-slot:item.order_service="{item}">
          <template v-if="item.order">
            <v-tooltip bottom v-if="item.order.employee_note">
              <template v-slot:activator="{ on, attrs }">
                <div class="item-service-name" v-bind="attrs" v-on="on">
                  {{ serviceGetName(item.order.service_id) }}
                </div>
              </template>
              <span>{{ item.order.employee_note }}</span>
            </v-tooltip>
            <template v-else>
              {{ serviceGetName(item.order.service_id) }}
            </template>
          </template>
        </template>
        <template v-slot:item.amount="{item}">
          <money-format v-if="item.amount" :value="item.amount"
                        :hide-subunits=true :locale="'vi'"
                        :currency-code="'VND'"
                        style="display: inline-block;">
          </money-format>
        </template>
        <template v-slot:item.status="{item}">
          <span v-if="item.status== 2 && item.payment_type == 2 && item.is_approve != 1">Reject</span>
          <span v-else>{{ paymentGetStatusText(item.status) }}</span>
        </template>
        <template v-slot:item.payment_type="{item}">
          {{ paymentGetTypeText(item.payment_type) }}
        </template>
        <template v-slot:item.created_at="{item}">
          {{ item.created_at }}
        </template>
        <template v-slot:item.actions="{item}">
          <template v-if="item.status== 1 && item.payment_type == 2 && item.is_approve != 1 && UserRole === CONSTANTS.ROLES.SUPER_ADMINISTRATOR">
            <span style="color: blue" @click="onClickApprove(item, 1)">Xác nhận |</span>
            <span style="color: blue" @click="onClickApprove(item, 0)">Từ chối</span>
          </template>
          <v-icon v-else small @click="onClickDetail(item)" class="mr-2" color="primary">mdi-view-list</v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialog.create" :width="800">
      <CreateItem :onCreated="onCreated" :onCancel="()=>{dialog.create=false}"></CreateItem>
    </v-dialog>
    <v-dialog v-model="dialog.detail" :width="'60%'">
      <DetailItem :selectedItem="selectedItem" :onClose="()=>{dialog.detail=false}"></DetailItem>
    </v-dialog>
    <v-dialog v-model="dialog.update" :width="800">
      <UpdateItem :onUpdated="onUpdated" :selectedItem="selectedItem" :onCancel="()=>{dialog.update=false}"></UpdateItem>
    </v-dialog>
    <v-dialog v-model="dialog.delete" :width="400">
      <DeleteItem :idsToDelete="idsToDelete" :onDeleted="onDeleted" :onCancel="()=>{dialog.delete=false}"></DeleteItem>
    </v-dialog>
    <v-dialog v-model="dialog.approve" :width="400">
      <ApproveItem :idsToApprove="idsToApprove" :is_approve="is_approve" :onApprove="onApprove" :onCancel="()=>{dialog.approve=false}"></ApproveItem>
    </v-dialog>
    <v-dialog v-model="dialog.import" :width="400">
      <ImportItem :onImported="onImported" :onCancel="()=>{dialog.import=false}"></ImportItem>
    </v-dialog>
    <v-dialog v-model="dialog.withdraw" :width="800">
      <WithdrawMoney :onRequestedWithdraw="onRequestedWithdraw" :onCancel="()=>{dialog.withdraw=false}"></WithdrawMoney>
    </v-dialog>
    <v-dialog v-model="dialog.discount" :width="600">
      <Discount :onCreated="onCreatedDiscount" :onCancel="()=>{dialog.discount=false}"></Discount>
    </v-dialog>
  </v-card>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import CreateItem from "./Create";
import DetailItem from "./Detail";
import UpdateItem from "./Update";
import DeleteItem from "./Delete";
import ApproveItem from "./Approve";
import ImportItem from "./Import";
import indexMixin from './indexMixin';
import WithdrawMoney from './WithdrawMoney';
import MoneyFormat from "vue-money-format";
import Discount from "@/admin/pages/ManagePayments/Discount";

export default {
  name: 'ManagePayments',
  components: {Discount, CreateItem, DetailItem, UpdateItem, DeleteItem, ImportItem, MoneyFormat, WithdrawMoney, ApproveItem},
  mixins: [indexMixin],
  data() {
    return {
      searchFields: {
        code: {
          value: '',
          type: 'like',
        },
        user_id: {
          value: '',
          type: 'like',
        },
        order_id: {
          value: '',
          type: 'like',
        },
        status: {
          value: '',
          type: 'like',
        },
        payment_type: {
          value: '',
          type: 'like',
        },
        bank_name: {
          value: '',
          type: 'like',
        },
        bank_number: {
          value: '',
          type: 'like',
        },
        'user.email': {
          value: '',
          type: 'like',
        },
      },
      searchWith: 'user;order',
      searchWithCount: '',
      tblHeader: [
        {
          text: 'No',
          value: 'no',
          sortable: false,
          groupable: false,
          width: '55px',
          align: 'start',
        },
        {
          text: 'Code',
          value: 'code',
        },
        {
          text: 'User',
          value: 'user.email',
        },
        {
          text: 'Order',
          value: 'order',
        },
        {
          text: 'Order Code',
          value: 'order_code',
        },
        {
          text: 'Order Service',
          value: 'order_service',
        },
        {
          text: 'Amount',
          value: 'amount',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          text: 'Payment Type',
          value: 'payment_type',
        },
        {
          text: 'Bank Name',
          value: 'bank_name',
        },
        {
          text: 'Bank Number',
          value: 'bank_number',
        },
        {
          text: 'Description',
          value: 'description',
        },
        {
          text: 'Created at',
          value: 'created_at',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          groupable: false,
          width: '111px',
          align: 'center',
        }
      ],
    }
  },
  computed: {
    ...mapGetters({
      items: 'payment/all',
      tblPagination: 'payment/pagination',
      paymentGetStatusText: 'payment/getStatusText',
      paymentGetTypeText: 'payment/getTypeText',
      paymentListStatus: 'payment/listStatus',
      paymentListType: 'payment/listPaymentType',
      serviceGetName: 'service/serviceName',
    }),
  },
  methods: {
    ...mapActions({
      getListItems: 'payment/get',
      exportListItems: 'payment/export',
    }),
  }
}
</script>
